<template>
  <div style="overflow: hidden; width: 100%; padding-inline: 10px">
    <MenuBar
      backgroundColor="linear-gradient(62deg, #362380 40.76%, #4AD594 91.64%)"
      optionColor="#FFFFFF"
      borderColor="none"
      clickColor="#FFFFFF"
      style="z-index: 2"
    ></MenuBar>

    <div class="heroSection">
      <div class="herotxt">
        <h6 class="dash">-</h6>
        <h6 class="txt">About Gorex Fuel Wallet</h6>
      </div>
      <h1 class="heroHead">
        Smart Choices Begin with Gorex's Efficient Fueling Solutions
      </h1>
    </div>

    <div class="paraContainer">
      <p class="paraText">
        Gorex Fuel Wallet transforms your fueling routine, linking you to a
        network of reliable fuel stations. Our intuitive solution ensures a
        hassle-free experience, enabling you to fulfil your fuel needs with
        state of art digital solution.
      </p>
      <p class="paraText">
        We meticulously vet our partner fuel stations, upholding rigorous
        standards for quality and professionalism. With Gorex Fuel Wallet, every
        transaction guarantees top-notch service and adherence to our stringent
        quality benchmarks
      </p>
      <p class="paraText">
        Whether you're an individual seeking flexible fueling options or a
        business aiming for efficient fuel management, Gorex Fuel Wallet caters
        to your requirements. Our dedicated support team is at your service,
        ready to address any questions and ensure a seamless experience.
      </p>
    </div>

    <div class="messageContainer row" v-motion-slide-visible-once-left>
      <div
        class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-1 order-md-2 msgCon2"
      >
        <img src="../assets/Images/ceo-img.svg" class="msgImg" alt="" />
      </div>

      <div
        class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-2 order-md-1 msgCon1"
      >
        <h5 class="msgHead">Chairman MESSAGE</h5>
        <h2 class="msgSubHead">
          Driving Innovation in the Automotive Industry.
        </h2>

        <p class="msgText">
          Gorex is a leading technology-based company in the automotive and
          transportation industry. We strive to provide transparent and
          efficient services to our customers by using innovative technology and
          data insights.
        </p>

        <p class="msgText">
          As CEO, I am proud to lead a team that is dedicated to meeting the
          ever-evolving needs of the industry, providing valuable services that
          enhance the quality of life for our clients. We continuously strive to
          be a trusted and reliable partner for our customers.
        </p>

        <h6 class="msgName">Asim Jamil</h6>
        <h6 class="msgDes">Chairman</h6>
      </div>
    </div>

    <div class="messageContainer row" v-motion-slide-visible-once-right>
      <div class="col-lg-6 col-md-6 col-sm-12 msgCon2">
        <img src="../assets/Images/ceo-img (1).svg" class="msgImg" alt="" />
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 msgCon1">
        <h5 class="msgHead">CEO'S MESSAGE</h5>
        <h2 class="msgSubHead">Leading the Fintech Revolution in Pakistan.</h2>

        <p class="msgText">
          Gorex Pakistan stands at the forefront of innovation in the digital
          payments landscape. As CEO, I am proud to lead our team in
          providingpioneering, secure, and reliable digital payment solutions
          for Corporate and SMEs. through our open-loop fuel management system.
        </p>

        <p class="msgText">
          Our commitment is to drive positive change, offering convenience
          andsecurity in every transaction. We are dedicated to meeting the
          dynamic needs of the industry, ensuring our clients benefit from
          cutting-edge Fintech solution. Trust Gorex Pakistan as your reliable
          partner in navigating the future of digital payments.
        </p>

        <h6 class="msgName">Syed Riaz Ud Din</h6>
        <h6 class="msgDes">CEO</h6>
      </div>
    </div>

    <div class="objContainer">
      <h5 class="msgHead">Company Objectives</h5>
      <h2 class="msgSubHead">
        Revolutionising Fuel Management with Gorex Pakistan Fuel Wallet
      </h2>

      <p class="msgText">
        Gorex Pakistan’s open-loop system ensures unparalleled flexibility,
        allowing swift and secure transactions at multiple fuel stations across
        various Oil Marketing Companies (OMCs) nationwide.
      </p>

      <p class="msgText">
        Enjoy the freedom of pay-as-you-go convenience, with control over fuel
        limits for corporate clients. We offer comprehensive fuelcard services,
        providing innovative, secure, and reliable solutions that drive positive
        societal impact.
      </p>

      <h6 class="msgName">
        Your key to a smarter, more connected fueling experience
      </h6>
    </div>

    <div
      class="row"
      style="
        display: flex;
        flex-direction: row;
        background-color: #fafafa;
        margin-bottom: 80px;
        margin-inline: 20px;
      "
    >
      <div
        class="col-lg-6 col-md-6 col-sm-12 col-12"
        style="margin-bottom: 30px"
      >
        <div class="vision">
          <h6 class="visiondash">-</h6>
          <h6 class="visionHead">OUR VISION</h6>
        </div>
        <p class="visionTxt">
          At Gorex Pakistan, our vision is to reshape the fuel management
          industry by introducing innovative Open Loop Fuel Wallet services. We
          are committed to providing easy access to fuel up through Gorex
          open-loop digital fuel card solution.
        </p>
        <p class="visionTxt">
          Our vision extends to empowering Corporate and SMEs, making Gorex the
          go-to destination for fuel stations and consumers seeking efficient
          and secure fuel transactions.
        </p>
        <p class="visionTxt">
          We aspire to redefine the fueling experience, fosteringinnovation and
          growth in the digital payments sector. Gorex Pakistan envisions
          creating a more connected, accessible, and efficient fueling ecosystem
          for all, ensuringour digital fuel card services become synonymous with
          reliability and excellence.
        </p>
      </div>

      <div
        class="col-lg-6 col-md-6 col-sm-12 col-12"
        style="background-color: #ff8b00"
      >
        <div class="mision">
          <h6 class="misiondash">-</h6>
          <h6 class="misionHead">OUR MISSION</h6>
        </div>
        <p class="misionTxt">
          Gorex Pakistan's mission is to establish a seamless and dependable
          digital platform for fuel managementservices. We prioritise
          transparency, trust, and conveniencein our digital fuel card
          offerings.
        </p>

        <p class="misionTxt">
          Our mission goes beyond being a provider we aim to be a trusted
          partner in fueling success. creating an uncomplicated experience for
          users seeking efficient fuel solutions, from routine refuelling to
          complex transactions.
        </p>

        <p class="misionTxt">
          Gorex Pakistan is dedicated to making high-quality fuel management
          accessible to everyone through our innovative digital fuel card
          services. We break down barriers, setting new standards in the mission
          to make fueling a hassle-free, efficient, and inclusive experience for
          all.
        </p>
      </div>
    </div>

    <FooterBar></FooterBar>
  </div>
</template>

<script>
import MenuBar from "./MenuBar.vue";
import FooterBar from "./Footer.vue";
import { useHead } from "@vueuse/head";
export default {
  name: "AboutUs",
  components: {
    MenuBar,
    FooterBar,
  },
  setup() {
    useHead({
      title: "Gorex Fuel Wallet | Fuel Management System and Industry",
      meta: [
        {
          name: "description",
          content:
            "Gorex.pk offers fuel card services and efficient fueling solutions, revolutionizing the fuel management industry with smart fuel management systems.",
        },
      ],
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heroSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: linear-gradient(62deg, #362380 40.76%, #4ad594 91.64%);
}

.herotxt {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 50px;
}

.dash {
  width: 20px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 2.5px;
  color: #ff8b00;
}

.txt {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.heroHead {
  color: #fff;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 100px;
}

.paraContainer {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.paraText {
  text-align: justify;
  color: rgba(23, 23, 23, 0.5);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.messageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  margin-bottom: 60px;
  margin-inline: 20px;
}

.msgCon1 {
  width: 100%;
  padding: 25px;
}

.msgHead {
  color: #ff8b00;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.msgSubHead {
  color: #171717;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
  justify-self: start;
}

.msgText {
  color: rgba(23, 23, 23, 0.5);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: justify;
  margin-bottom: 35px;
}

.msgName {
  color: rgba(23, 23, 23, 0.5);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.msgDes {
  color: rgba(23, 23, 23, 0.5);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.msgCon2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-block: 20px; */
}

.msgImg {
  width: 30vw;
  height: 30vw;
  border-top-left-radius: 40%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.objContainer {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  margin-bottom: 80px;
  margin-inline: 20px;
  padding: 30px;
}

.vision {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 30px;
}

.visiondash {
  width: 20px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 10px;
  color: #ff8b00;
}

.visionHead {
  color: #ff8b00;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.visionTxt {
  color: rgba(23, 23, 23, 0.5);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  margin-inline: 30px;
  text-align: justify;
}

.mision {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 37px;
}

.misiondash {
  width: 20px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 10px;
  color: #fff;
}

.misionHead {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.misionTxt {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  margin-inline: 37px;
  text-align: justify;
}

@media (max-width: 700px) {
  .heroHead {
    font-size: 35px;
    /* Font size for small screens (576px and below) */
  }

  .msgSubHead {
    font-size: 30px;
  }
  .msgImg {
    width: 80vw;
    height: 50vw;
  }
}
</style>
